import request from '@/utils/request'


// 查询报名学生列表
export function listStudent(query) {
  return request({
    url: '/driving-school/driving-school-student/list',
    method: 'get',
    params: query
  })
}

// 查询报名学生分页
export function pageStudent(query) {
  return request({
    url: '/driving-school/driving-school-student/page',
    method: 'get',
    params: query
  })
}

// 查询报名学生详细
export function getStudent(data) {
  return request({
    url: '/driving-school/driving-school-student/detail',
    method: 'get',
    params: data
  })
}

// 新增报名学生
export function addStudent(data) {
  return request({
    url: '/driving-school/driving-school-student/add',
    method: 'post',
    data: data
  })
}

// 修改报名学生
export function updateStudent(data) {
  return request({
    url: '/driving-school/driving-school-student/edit',
    method: 'post',
    data: data
  })
}

// 删除报名学生
export function delStudent(data) {
  return request({
    url: '/driving-school/driving-school-student/delete',
    method: 'post',
    data: data
  })
}
