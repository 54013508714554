<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="${comment}" prop="ydtId" >
        <a-input v-model="form.ydtId" placeholder="请输入${comment}" />
      </a-form-model-item>
      <a-form-model-item label="学员姓名" prop="username" >
        <a-input v-model="form.username" placeholder="请输入学员姓名" />
      </a-form-model-item>
      <a-form-model-item label="民族" prop="nationality" >
        <a-input v-model="form.nationality" placeholder="请输入民族" />
      </a-form-model-item>
      <a-form-model-item label="性别" prop="sex" >
      </a-form-model-item>
      <a-form-model-item label="身份证号" prop="idCard" >
        <a-input v-model="form.idCard" placeholder="请输入身份证号" />
      </a-form-model-item>
      <a-form-model-item label="证件起日期" prop="idCardStartTime" >
        <a-input v-model="form.idCardStartTime" placeholder="请输入证件起日期" />
      </a-form-model-item>
      <a-form-model-item label="证件止日期" prop="idCardEndTime" >
        <a-input v-model="form.idCardEndTime" placeholder="请输入证件止日期" />
      </a-form-model-item>
      <a-form-model-item label="出生日期" prop="birthday" >
        <a-input v-model="form.birthday" placeholder="请输入出生日期" />
      </a-form-model-item>
      <a-form-model-item label="联系电话" prop="phone" >
        <a-input v-model="form.phone" placeholder="请输入联系电话" />
      </a-form-model-item>
      <a-form-model-item label="校区" prop="schoolId" >
        <a-input v-model="form.schoolId" placeholder="请输入校区" />
      </a-form-model-item>
      <a-form-model-item label="报名点" prop="sitesId" >
        <a-input v-model="form.sitesId" placeholder="请输入报名点" />
      </a-form-model-item>
      <a-form-model-item label="介绍人" prop="inviter" >
        <a-input v-model="form.inviter" placeholder="请输入介绍人" />
      </a-form-model-item>
      <a-form-model-item label="业务类型" prop="businessType" >
        <a-select placeholder="请选择业务类型" v-model="form.businessType">
          <a-select-option v-for="(d, index) in businessTypeOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="报名车型" prop="carType" >
        <a-select placeholder="请选择报名车型" v-model="form.carType">
          <a-select-option v-for="(d, index) in carTypeOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="班制" prop="classType" >
        <a-select placeholder="请选择班制" v-model="form.classType">
          <a-select-option v-for="(d, index) in classTypeOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="支付方式" prop="payType" >
        <a-select placeholder="请选择支付方式" v-model="form.payType">
          <a-select-option v-for="(d, index) in payTypeOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="支付金额" prop="payMoney" >
        <a-input v-model="form.payMoney" placeholder="请输入支付金额" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入备注" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getStudent, addStudent, updateStudent } from '@/api/driving-school/student'

export default {
  name: 'CreateForm',
  props: {
    businessTypeOptions: {
      type: Array,
      required: true
    },
    carTypeOptions: {
      type: Array,
      required: true
    },
    classTypeOptions: {
      type: Array,
      required: true
    },
    payTypeOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        ydtId: null,

        username: null,

        nationality: null,

        sex: null,

        idCard: null,

        idCardStartTime: null,

        idCardEndTime: null,

        birthday: null,

        phone: null,

        schoolId: null,

        sitesId: null,

        inviter: null,

        businessType: null,

        carType: null,

        classType: null,

        payType: null,

        payMoney: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        ydtId: null,
        username: null,
        nationality: null,
        sex: null,
        idCard: null,
        idCardStartTime: null,
        idCardEndTime: null,
        birthday: null,
        phone: null,
        schoolId: null,
        sitesId: null,
        inviter: null,
        businessType: null,
        carType: null,
        classType: null,
        payType: null,
        payMoney: null,
        createTime: null,
        remark: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getStudent({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateStudent(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addStudent(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
